import React from "react";
import Select from "react-select";

function InhoudsOpgave({ defaults, toParent, edit }) {
  let inhoudsOpgave = [];
  if (edit === true) {
    inhoudsOpgave = [
      { value: 1, label: "Gebreken" },
      { value: 2, label: "Advies" },
      { value: 3, label: "Thermografisch" },
      { value: 4, label: "Herstelwerkzaamheden" },
      { value: 5, label: "Herstelverklaring" },
      { value: 6, label: "Inspectieplan" },
      { value: 7, label: "Meetresultaten" },
      { value: 8, label: "Eindbeoordeling" },
    ];
  } else {
    inhoudsOpgave = [
      { value: 1, label: "Gebreken" },
      { value: 2, label: "Advies" },
      { value: 4, label: "Herstelwerkzaamheden" },
      { value: 5, label: "Herstelverklaring" },
      { value: 6, label: "Inspectieplan" },
      { value: 7, label: "Meetresultaten" },
      { value: 8, label: "Eindbeoordeling" },
    ];
  }

  let inhoudsopgave = [];
  if (defaults !== undefined) {
    inhoudsopgave = defaults.map((object, i) => {
      let selectedOptions = inhoudsOpgave.filter(
        (obj_options) =>
          obj_options.label ==
          (typeof object === "string" ? object : object.label)
      );
      return {
        value:
          (selectedOptions[0] && selectedOptions[0].value) ??
          (selectedOptions && selectedOptions.value),
        label:
          (selectedOptions[0] && selectedOptions[0].label) ??
          (selectedOptions && selectedOptions.label),
        volgorde: i,
      };
    });
  }

  return (
    <Select
      defaultValue={inhoudsopgave}
      options={inhoudsOpgave}
      onChange={(e) => toParent(e.map((object) => object.label))}
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
    />
  );
}

export default InhoudsOpgave;
