import React, { useEffect, useState } from "react";
export const MediaItem = ({ mediaItem }) => {
  const [urlExists, setUrlExists] = useState(false);

  // useEffect(() => {
  //   const checkUrl = async () => {
  //     try {
  //       const response = await fetch(mediaItem.url, {
  //         method: "GET",
  //       });
  //       setUrlExists(response.status !== 404);
  //     } catch (error) {
  //       console.error(`Error fetching media URL: ${mediaItem.url}`, error);
  //     }
  //   };

  //   checkUrl();
  // }, [mediaItem.url]);

  // if (!urlExists) return null;

  return (
    <div>
      <img
        src={mediaItem.url}
        alt={mediaItem.naam}
        className="w-48 h-48 object-cover rounded-md border"
      />
    </div>
  );
};
